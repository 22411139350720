import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/bodycomponents/contact-form"
import FAQ from "../../components/faq-components/faq"
import { relocationFaqItems } from "../../utils/data/faq"
import TopHeroComponent from "../../components/herocomponents/topherocomponent"
import R_BLUE_BG from "../../images/background-images/bg-royal-blue.svg"
import M_GRAY_BG from "../../images/background-images/bg-medium-gray.svg"
import { colors } from "../../utils/colors"
import CardComponent from "../../components/bodycomponents/card-component"
import { Link } from "gatsby"
import sanofi_logo from "../../images/Sanofi-Logo-600x277.png"

const RelocationPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Tailored Relocation Services for Equipment & Facilities`}
          description={`Surplus Solutions provides tailored relocation services for smooth, efficient moves of equipment and facilities to meet all your project needs.`}
        />
        <TopHeroComponent
          backgroundImage={R_BLUE_BG}
          backgroundColor={colors.blue}
          shadowColor="shadow-royalBlue-bottom"
          fontShadowColor={colors.royalBlue}
          pageTitle="Relocation Services"
          title="Seamless Laboratory Relocation Services"
          body={
            <p className="pt-4">
              Backed by 25+ years of expertise, Surplus Solutions ensures safe
              and efficient lab relocations. Our team expertly plans and
              executes every move, managing all details for a smooth transition
              tailored to your needs.
            </p>
          }
        />
        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl py-14">
            <div className="text-left">
              <h2 className="pb-2">Laboratory Relocation Process</h2>
              <h3>1. Pre-Move Planning</h3>
              <p className="pb-6">
                Pre-move planning is an integral part of our laboratory
                relocation services—because it’s key to making sure everything
                that comes after happens the way it should. Our team performs a
                full site assessment and creates a customized plan designed
                around your requirements, budget, and schedule.
              </p>
              <h3>2. Logistics Coordination</h3>
              <p className="pb-6">
                We handle all{" "}
                <Link to={"/our-services/logistics-services"}>logistics</Link>{" "}
                on your behalf, partnering with industry-leading moving
                companies to ensure the safe and secure handling of your assets.
                Need storage? We have warehouses conveniently located in
                Woonsocket, RI, Murrieta, CA, and Vendenheim, France, as well as
                partner facilities in other areas.
              </p>
              <h3>3. Packing & Crating</h3>
              <p className="pb-6">
                Our team expertly packs and crates your assets with premium
                materials to ensure secure transportation. As part of our
                laboratory relocation services, we also provide customized
                crating solutions to ensure your precious assets are transported
                safely, regardless of size.
              </p>
              <h3>4. Transporting</h3>
              <p className="pb-6">
                After packing, your equipment is securely loaded and transported
                by boat, truck, air, or rail—whichever method best suits your
                specific needs. Whether you’re relocating locally, nationally,
                or globally, we have the resources and networks to ensure safe,
                prompt, transport of assets.
              </p>
              <h3>5. Unpacking & Setting Up</h3>
              <p>
                When your equipment reaches its destination, our professional
                laboratory movers carefully unpack, set up, and reinstall your
                equipment exactly where it needs to go, ensuring minimal
                disruption to your operations.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-light-gray shadow-custom-in">
          <div className="container max-w-screen-xl py-14">
            <h2 className="text-charcoal pb-4 mb-0 text-center">
              Case Study: Rapid Decommissioning and Redeployment for Sanofi
            </h2>
            <div className="w-full flex flex-col md:flex-row">
              <div className="w-full pb-6 md:pb-0 md:w-1/3 px-6 md:px-4">
                <img src={sanofi_logo} alt="Sanofi Logo" width="600px" />
              </div>
              <div className="w-full md:w-2/3">
                <h4 className="mb-0 mt-[10px]">Project Overview</h4>
                <p className="pb-5">
                  When Sanofi acquired TargeGen’s 40,000 sq. ft. facility in San
                  Diego, CA, they were faced with a looming deadline. With the
                  lease expiring, all laboratory assets, chemicals, and office
                  equipment needed to be decommissioned and removed within just
                  three weeks.
                </p>
                <h4 className="mb-0 mt-[10px]">Process</h4>
                <p className="pb-5">
                  Our team stepped in with a swift, strategic approach.
                  Partnering with Veolia, we ensured the safe decommissioning of
                  equipment and removal of chemicals from the lab. Meanwhile, we
                  facilitated the redeployment of essential instruments to other
                  Sanofi sites across Arizona, Pennsylvania, New Jersey, and
                  Massachusetts. We also negotiated a competitive asset purchase
                  from Sanofi, managing the removal, packaging, and shipment of
                  twelve truckloads of equipment to our Fall River, MA facility.
                  There, we executed a highly successful online auction.
                </p>
                <h4 className="mb-0 mt-[10px]">Result</h4>
                <p className="">
                  Our team not only met but exceeded all project timelines and
                  client expectations by delivering maximum value. We completed
                  the removal efficiently, and left the facility in spotless
                  condition, fully meeting client standards.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="shadow-custom-out">
          <div className="container max-w-screen-xl pt-14 pb-10">
            <h2 className="text-center mb-[30px]">
              Why Choose Surplus Solutions?
            </h2>
            <div className="grid gap-9 lg:grid-cols-3">
              <CardComponent
                title="Safety"
                body={
                  <p>
                    Safe delivery of your assets is our top priority. Our
                    dedicated fleet of AirRide trucks, equipped with advanced
                    suspension technology, ensures smooth and secure transport
                    for every laboratory relocation.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title="Efficiency"
                body={
                  <p>
                    With Surplus Solutions, you don’t need to worry about
                    coordinating your move with multiple vendors. Our laboratory
                    relocation experts handle every detail, streamlining the
                    process for a faster, more efficient, and less-stressful
                    transition.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
              <CardComponent
                title="Cost-Effectiveness"
                body={
                  <p>
                    Another benefit of eliminating the need for multiple vendors
                    is that Surplus Solutions can help you reduce expenses.
                    Based on our experience and hands-on knowledge, we offer
                    highly competitive pricing on all lab relocation services,
                    including storage.
                  </p>
                }
                backgroundColor={colors.containerGray}
                shadow="shadow-scarletRed-bottom"
                padding="p-[35px] lg:p-[1.875rem]"
              />
            </div>
          </div>
        </div>
        <FAQ
          title="Relocation FAQs"
          items={relocationFaqItems}
          backgroundColor={colors.lightGray}
          backgroundImage={M_GRAY_BG}
          shadowColor="shadow-mediumGray-top"
        />
        <ContactForm
          title="Ready to relocate your laboratory with confidence?"
          body={
            <p>
              Let our team handle every detail of your lab move—no matter the
              scale or distance. Get in touch today for a free consultation and
              experience seamless, stress-free relocation.
            </p>
          }
          backgroundColor={colors.containerGray}
          fontShadowColor={colors.royalBlue}
          shadow="shadow-royalBlue-bottom"
          formType="Contact"
        />
      </Layout>
    </>
  )
}

export default RelocationPage
